<template>
  <div id="app">
    <h1>Coin Marketcap</h1>
    <div class="container">
      <div class="filters">
        <div class="filter-block">
          <div class="input-group">
            <label for="coin-name">Tên Coin:</label>
            <input v-model="coinName" id="coin-name" type="text" placeholder="Nhập tên coin" />
          </div>

          <div class="input-group">
            <label for="min-marketcap">Min Marketcap (USD):</label>
            <input v-model.number="minMarketcap" id="min-marketcap" type="number" placeholder="Min marketcap" />
          </div>
        </div>
        
        <div class="filter-block">
          <div class="input-group">
            <label for="max-marketcap">Max Marketcap (USD):</label>
            <input v-model.number="maxMarketcap" id="max-marketcap" type="number" placeholder="Max marketcap" />
          </div>

          <div class="input-group">
            <label for="sort-order">Sort by Marketcap:</label>
            <select v-model="sortOrder" id="sort-order" class="sort-order">
              <option value="asc">increase</option>
              <option value="desc">reduce</option>
            </select>
          </div>
        </div>

        <div class="button-container">
          <button @click="fetchMarketcap">Search</button>
          <button @click="clearFilters">Clear</button>
          <button @click="updateMarketcap">Get new Marketcap</button>
        </div>
      </div>

      <div class="results">
        <div class="loading" v-if="loading"></div>
        <div v-if="paginatedCoins.length > 0 && !loading">
          <table border="1" cellpadding="10" cellspacing="0">
            <thead>
              <tr>
                <th>STT</th>
                <th>Coin</th>
                <th>Max Supply</th>
                <th>Market Cap (USD)</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(coin, index) in paginatedCoins" :key="coin.coin_name" v-bind:class="{ 'highlight-red': coin.maxSupply == 1 }">
                <td>{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
                <td>{{ coin.coinName }}</td>
                <td>
                  <input v-if="coin.isEditing" v-model="coin.newMaxSupply" type="number" />
                  <span v-else>{{ coin.maxSupply.toLocaleString() }}</span>
                </td>
                <td>{{ coin.marketcap.toLocaleString() }}</td>
                <td>
                  <button class="btn-action" v-if="!coin.isEditing" @click="editMaxSupply(coin)">✏️</button>
                  <button class="btn-action" v-else @click="saveMaxSupply(coin)">💾</button>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="pagination">
            <select v-model="itemsPerPage" @change="currentPage = 1">
              <option :value="10">10</option>
              <option :value="50">50</option>
              <option :value="100">100</option>
              <option :value="200">200</option>
            </select>
            <button class="bth-pagi" @click="prevPage" :disabled="currentPage === 1">Previous</button>
            <span>Page {{ currentPage }} of {{ totalPages }}</span>
            <button class="bth-pagi" @click="nextPage" :disabled="currentPage === totalPages">Next</button>
          </div>
        </div>
        <div v-else-if="!loading && !error">
          <p>No coins found for the specified conditions.</p>
        </div>
        <div v-else-if="error">
          <p>{{ error }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API_URL } from './config';

export default {
  data() {
    return {
      coinName: '',
      minMarketcap: null,
      maxMarketcap: null,
      sortOrder: 'asc',
      coins: [],
      currentPage: 1,
      itemsPerPage: 10,
      loading: false,
      error: null
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.coins.length / this.itemsPerPage);
    },
    paginatedCoins() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.coins.slice(start, end);
    }
  },
  methods: {
    async fetchMarketcap() {
      this.loading = true;
      this.error = null;

      const payload = {
        coinName: this.coinName,
        minMarketcap: this.minMarketcap ? this.minMarketcap.toString() : "",
        maxMarketcap: this.maxMarketcap ? this.maxMarketcap.toString() : "",
        sortOrder: this.sortOrder
      };

      try {
        const response = await fetch(`${API_URL}/tool/getmarketcap`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data. Please try again later.');
        }

        const data = await response.json();
        this.coins = data || [];
      } catch (error) {
        this.error = error.message;
        this.coins = [];
      } finally {
        this.loading = false;
      }
      this.currentPage = 1;
    },

    async updateMarketcap() {
      this.loading = true;
      this.error = null;

      try {
        const response = await fetch(`${API_URL}/tool/update-marketcap`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
        });

        if (!response.ok) {
          throw new Error('Failed to update marketcap. Please try again later.');
        }

        alert('Marketcap updated successfully!');
        
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },

    editMaxSupply(coin) {
      coin.isEditing = true;
      coin.newMaxSupply = coin.maxSupply;
    },

    async saveMaxSupply(coin) {
      try {
        const confirmed = confirm("Are you sure you want to save the updated Max Supply?");
        if (!confirmed) {
          return;
        }

        const payload = {
          coinName: coin.coinName,
          maxSupply: coin.newMaxSupply
        };
        const response = await fetch(`${API_URL}/tool/update`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });

        if (!response.ok) {
          throw new Error('Failed to update max supply.');
        }

        coin.maxSupply = coin.newMaxSupply;
        coin.isEditing = false;
      } catch (error) {
        this.error = error.message;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage += 1;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
      }
    },
    clearFilters() {
      this.coinName = '';
      this.minMarketcap = null;
      this.maxMarketcap = null;
      this.sortOrder = 'asc';
      this.coins = [];
      this.error = null;
      this.currentPage = 1;
    }
  },
};
</script>

<style scoped>
.highlight-red {
  background-color: #ffcccc;
  color: #ff0000;
  font-weight: bold;
}

.pagination select {
  margin-right: 10px;
  padding: 8px;
  font-size: 14px;
}

.pagination {
  display: flex;
  margin-top: 20px;
}
.pagination button {
  padding: 8px 16px;
  margin: 0 5px;
  font-size: 14px;
  cursor: pointer;
}
.pagination span {
  font-size: 16px;
  line-height: 32px;
}
.loading {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

.btn-action {
  padding: 0px 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.sort-order, .limit-select {
  padding: 10px;
  width: 170px;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.container {
  display: flex;
  gap: 20px;
  margin: 0 auto;
  max-width: 1200px;
}

.filters, .results {
  flex: 1;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.filters {
  max-width: 400px;
}

.filter-block {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.input-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
}

label {
  font-weight: bold;
}

input, select {
  padding: 8px;
  font-size: 16px;
  width: 150px;
  border: 2px solid #ccc; /* Viền mặc định */
  border-radius: 5px;
  transition: all 0.3s ease; /* Thêm hiệu ứng khi thay đổi */
}

input:focus, select:focus {
  outline: none; /* Loại bỏ viền mặc định khi focus */
  border-color: #3498db; /* Viền xanh biển khi focus */
  box-shadow: 0 0 5px rgba(52, 152, 219, 0.5); /* Hiệu ứng bóng đổ khi focus */
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

/* Nút "Advanced Search" màu vàng */
button:nth-child(3) {
  background-color: #f39c12; /* Màu vàng */
  color: white;
}

button:nth-child(3):hover {
  background-color: #e67e22; /* Màu vàng đậm khi hover */
}

/* Nút "Search" màu xanh biển */
button:nth-child(1) {
  background-color: #3498db; /* Màu xanh biển */
  color: white;
}

button:nth-child(1):hover {
  background-color: #2980b9; /* Màu xanh biển đậm khi hover */
}

/* Nút "Clear" màu hồng */
button:nth-child(2) {
  background-color: #e74c3c; /* Màu hồng */
  color: white;
}

button:nth-child(2):hover {
  background-color: #c0392b; /* Màu hồng đậm khi hover */
}
</style>

